@import '../../../assets/scss/_base.scss';
.industry {
  margin-top: 48px;
  padding-top: 60px;
  height: 773px;
  background-image: url('~@/assets/images/industry/industry_background.png');
  background-repeat: no-repeat;
  width: 100%;
}

.content {
  margin: 80px auto 0px auto;
  display: flex;
  width: 1200px;
  flex-flow: wrap;
  justify-content: space-between;
}

.industryItem {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding: 16px 32px;
  width: 592px;
  background-color: #016FE5;
  font-size: 18px;
  color: #FFFFFF;

  .pic {
    display: block;
    margin-right: 36px;
    width: 36px;
    height: 36px;
    border: none;
  }

  .activePic {
    display: none;
  }
}

.industryItem:hover {
  background-color: #D2EAFF;
  color: #016FE5;
  
  .defaultPic {
    display: none;
  }

  .activePic {
    display: block;
  }
}

