@import '../../../assets/scss/_base.scss';
.introLand {
  width: 100%;
  height: 1790px;
  background-image: url('~@/assets/images/toolsbox/intro_background.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  margin: 0px 160px;
}

.list {
  padding-top: 50px;
}

.listItem {
  margin-bottom: 50px;
  color: #FFFFFF;
  font-size: 30px;
  font-weight: 400;
}

.question {
  display: inline-block;
  margin-bottom: 50px;
  padding: 36px 64px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0px 58px 58px 58px;
}

.answer {
  padding: 36px 64px;
  line-height: 1.5;
  background-color: rgba(255, 255, 255, 0.14);
  border-radius: 100px 0px 100px 100px;
}