@import '../../../assets/scss/_base.scss';
.superiority {
  margin-top: 450px;
  width: 100%;
}

.content {
  margin: 110px auto 0px auto;
  width: 1599px;
}

.superioritySwiper {
  .superiorityWrapper {
    margin-bottom: 50px;

    &:hover {
      .buttonNext {
        background-image: url('~@/assets/images/superiority/icon_next_hover.png');
        background-repeat: no-repeat;
      }

      .buttonPrev {
        background-image: url('~@/assets/images/superiority/icon_prev_hover.png');
        background-repeat: no-repeat;
      }
    }
  }

  .superiorityPagination {
    top: 410px;
    left: 50%;
    transform: translateX(-50%);
    width: 360px;
    background-color: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
  }
}



.brickItem {
  padding-bottom: 70px;
  background-color: #FFFFFF;
  border: 1px solid #0F78F8;

  &:first-child {
    margin-left: 0px;
  }

  &:hover {
    background: linear-gradient(180deg, #22B0FC 0%, #0F78F8 100%);
    border: 1px solid #979797;
    color: #FFFFFF;

    .defaultPic {
      display: none;
    }

    .activePic {
      display: block;
    }

    .title, .desc {
      color: #FFFFFF;
    }
  }
}

.imgBox {
  display: flex;
  align-items: center;
  margin: 100px auto 40px auto;
  width: 85px;
  height: 85px;
}

.pic {
  display: block;
  width: 100%;

  &.activePic {
    display: none;
  }
}

.title {
  margin: 0px 10px 12px 10px;
  text-align: center;
  font-size: 28px;
  color: #117EF8;
}

.desc {
  margin: 0px 10px 12px 10px;
  font-size: 18px;
  color: #333333;
  text-align: center;
}

.buttonNext {
  top: 45%;
  right: 0px;
  width: 32px;
  height: 50px;
  background-image: url('~@/assets/images/superiority/icon_next_default.png');
  background-repeat: no-repeat;
}

.buttonPrev {
  top: 45%;
  left: 0px;
  width: 32px;
  height: 50px;
  background-image: url('~@/assets/images/superiority/icon_prev_default.png');
  background-repeat: no-repeat;
}

