@import '../../../assets/scss/_base.scss';
.scheme {
  width: 100%;
  height: 1128px;
  background-image: url('~@/assets/images/scheme/background.png');
  background-repeat: no-repeat;
}

.content {
  margin: 0px auto;
  padding-top: 106px;
  width: 1542px;

  .schemePagination {
    position: absolute;
    top: 208px;
  }
}

.slideContent {
  display: flex;
}

.slideItem {
  margin-left: 18px;
  width: 372px;
  
  &:first-child {
    margin-left: 0px;
  }

  &.active {
    .bottomSlideContent {
      background-color: #0F78F8;
    }

    .defaultPic, .topDefaultPic {
      display: none;
    }

    .activePic, .topActivePic {
      display: block;
    }

    .name, .desc {
      color: #FFFFFF;
    }

    .topName {
      color: #0F78F8;
    }
  }
}

.topSlideContent {
  margin-bottom: 120px;
}

.bottomSlideContent {
  position: relative;
  padding: 94px 54px;
  height: 564px;
  background-color: #FFFFFF;
  border-radius: 4px;
}

.iconRight {
  position: absolute;
  bottom: 90px;
  width: 53px;
  height: 15px;
  background-image: url('~@/assets/images/scheme/icon_right.png');
  background-repeat: no-repeat;
}

.topPic, .pic {
  display: block;
  width: 100px;
  height: 94px;
}

.topPic {
  margin: 0px auto 26px auto;
}

.topName {
  font-size: 24px;
  color: #959595;
  text-align: center;
}

.topActivePic, .activePic {
  display: none;
}

.name {
  margin-top: 44px;
  font-size: 26px;
  color: #333333;
}

.desc {
  margin-top: 16px;
  font-size: 24px;
  color: #666666;
  line-height: 1.5;
}

.buttonNext {
  top: 56px;
  right: 32px;
  background-image: url('~@/assets/images/scheme/icon_next.png');
  background-repeat: no-repeat;
}

.buttonPrev {
  top: 56px;
  left: 32px;
  background-image: url('~@/assets/images/scheme/icon_prev.png');
  background-repeat: no-repeat;
}
