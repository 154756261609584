@import '../../../assets/scss/_base.scss';
.firstScreen {
  display: block;
  width: 100%;
  height: 600px;
  background-image: url('~@/assets/images/supply/first_screen_background.png');
  background-repeat: no-repeat;
}

.content {
  margin: 0px auto;
  padding-top: 172px;
  width: 1600px;
}

.title {
  font-size: 72px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: normal;
}

.description {
  margin-top: 24px;
  font-size: 28px;
  color: rgba(255, 255, 255, 0.70);
}

.btn {
  margin-top: 36px;
  display: block;
  width: 220px;
  height: 60px;
  line-height: 60px;
  font-size: 22px;
  font-weight: bold;
  color: rgba(14, 135, 241, 1);
  background-color: #FFFFFF;
  border-radius: 100px;
  border: 1px solid #707070;
  text-align: center;
}