@import '../../../../assets/scss/_base.scss';
.title {
  font-size: 48px;
  color: #333333;
  text-align: center;
}

.desc {
  margin-top: 12px;
  font-size: 24px;
  color: #555555;
  text-align: center;
}