@import '../../../assets/scss/_base.scss';
.promote {
  position: relative;
  width: 100%;
  height: 217px;
  overflow: hidden;
}

.backgroundImage {
  display: block;
  width: 100%;
  transform: scale(1.02);
  transition: all 1s;

  &:hover {
    transform: scale(1);
  }
}

.content {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 34px;
  width: 560px;
  text-align: center;
}

.title {
  font-size: 36px;
  font-weight: 400;
  color: rgb(254, 254, 254);
}

.desc {
  margin: 24px auto 0px auto;
  font-size: 24px;
  color: rgb(254, 254, 254);
}

.btn {
  display: inline-block;
  padding: 10px 35px;
  margin: 24px auto 0px auto;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  user-select: none;

  &:hover {
    color: rgb(255, 255, 255);
  }
}