@import '../../assets/scss/_base.scss';
.container {
  background-color: rgba(255, 255, 255, 1);
}

.aboutSection {
  padding-bottom: 150px;
}

.firstScreen {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.backgroundImage {
  display: block;
  width: 100%;
  transform: scale(1.02);
  transition: all 1s;

  &:hover {
    transform: scale(1);
  }
}

.firstContent {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  width: 480px;
  line-height: 1.3;
}

.firstTitle {
  font-size: 65px;
  font-weight: 550;
  color: #FFFFFF;
  text-align: center;
}

.firstDesc {
  margin-top: 24px;
  font-size: 24px;
  color: #FFFFFF;
  text-align: center;
}

.divp {
  display: flex;
  width: 800px;
  justify-content: center;
  margin: 0 auto;
}

.content {
  margin: 100px auto 0px auto;
  width: 1200px;

  li {
    margin-top: 100px;
    text-align: center;
    color: #19191D;
    font-size: 16px;
    font-family: 'SourceHanSansCN-Medium', 'Arial';

    &:first-child {
      margin-top: 0px;
    }

    p {
      line-height: 26px;
      text-align: left;
      margin: 0px 30px;
      color: #333333;
    }
  }
}

.label {
  display: block;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.title {
  margin-bottom: 33px;
  font-size: 30px;
}

.valueContent {
  margin-top: 56px;
}

.valueCol {
  display: inline-block;
  vertical-align: top;
  margin-left: 52px;
  width: 190px;
  height: 190px;
  border-radius: 50% 50%;
  background-color: #0F78F8;
  font-size: 46px;
  color: #FFFFFF;
  font-family: 'SourceHanSansCN-Medium';
  text-align: center;
  line-height: 190px;

  &:first-child {
    margin-left: 0px;
  }
}





