@import '../../../assets/scss/_base.scss';
.title {
  position: relative;
  padding: 28px 0px;
  margin: 0px auto;
  width: 460px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: rgb(51, 51, 51);
  border-bottom: 1px solid rgb(221, 221, 221);

  &::after {
    position: absolute;
    bottom: -1.5px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    display: block;
    width: 60px;
    height: 3px;
    background-color: #0034BB;
  }
}