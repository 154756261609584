@import '../../../assets/scss/_base.scss';
.brand {
  background-color: rgb(255, 255, 255);
  padding-bottom: 150px;
  color: rgb(82, 170, 243);
  width: 100%
}

.content {
  margin: 50px auto 0px auto;
  width: 1200px;
}

.label {
  display: inline-block;
  color: black;
  font-size: 20px;
  text-align: center;
  vertical-align: middle;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brickItem {
  float: left;
  position: relative;
  width: 230px;
  margin: 25px 10px 20px 0px;

  .txt {
    font-size: 16px;
    color: $baseDecColor;
  }
}


.pic {
  display: block;
  margin: 0px auto;
  width: 218px;
  height: 102px;

}

.text {
  margin: 0px 15px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(51, 51, 51);
  text-align: center;
}

.adec {
  color: black;
  font-family: '微软雅黑';
  //font-weight: bold;
}

.decspan {
  display: inline-block;
  padding: 3px 6px;
  font-size: 18px;
  vertical-align: middle;
  margin-left: 14px;
  text-align: center;
}

.ischeck {
  background-color: #0034BB;
  color: white;
  border-radius: 4px;

  a {
    color: white;
  }
}

.divul {
  position: relative;
}

.btn {
  position: relative;
  top: 10px;
  width: 135px;
  height: 37px;
  border: 1px solid #0034BB;
  opacity: 1;
  border-radius: 4px;
  margin: 60px auto 0px auto;
  text-align: center;
  line-height: 35px;

  a {
    width: 64px;
    height: 21px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 28px;
    color: #0034BB;
    opacity: 1;
  }
}