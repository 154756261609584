@import '../../../assets/scss/_base.scss';
.firstScreen {
  width: 100%;
  height: 1080px;
  background-image: url('~@/assets/images/toolsbox/first_background.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  position: relative;
  padding-top: 321px;
}

.btn {
  display: block;
  margin: 0px auto 51px auto;
  width: 220px;
  height: 60px;
  line-height: 60px;
  background-color: #FFFFFF;
  border: 1px solid #707070;
  border-radius: 100px;
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  color: #6A39FF;

  &:hover {
    color: #6A39FF;
  }
}