@import '../../../assets/scss/_base.scss';
.cooperation {
  position: relative;
  padding: 54px 0px 72px 0px;
  background-color: #000000;
}

.title {
  margin: 0px auto;
  width: 1200px;
  font-size: 36px;
  color: #FFFFFF;
  line-height: normal;
  text-align: center;
}

.subtitle {
  margin: 12px auto 0px auto;
  width: 1200px;
  font-size: 24px;
  color: #555555;
  line-height: normal;
  text-align: center;
}

.content {
  position: relative;
  margin: 54px auto 0px auto;
  width: 1200px;
}

.map {
  position: relative;
  margin: 0px auto;
  width: 913px;
  height: 745px;
  background-image: url('~@/assets/images/cooperation/map.png');
  background-repeat: no-repeat;
}

.groupMap {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 114px;
  height: 171px;
  background-image: url('~@/assets/images/cooperation/group_map.png');
  background-repeat: no-repeat;
}

.region {
  width: 77px;
  height: 77px;
  position: absolute;
  z-index: 8;
  text-align: center;

  &.position1 {
    top: 216px;
    right: 204px;

    &:hover {
      background-image: url('~@/assets/images/cooperation/beijing_hover.png');
      background-repeat: no-repeat;

      .defaultIcon {
        display: none;
      }

      .activeIcon {
        display: block;
      }
    }
  }

  &.position2 {
    top: 232px;
    right: 170px;

    &:hover {
      background-image: url('~@/assets/images/cooperation/tianjin_hover.png');
      background-repeat: no-repeat;

      .defaultIcon {
        display: none;
      }

      .activeIcon {
        display: block;
      }
    }
  }

  &.position3 {
    top: 402px;
    right: 110px;

    &:hover {
      background-image: url('~@/assets/images/cooperation/shanghai_hover.png');
      background-repeat: no-repeat;

      .defaultIcon {
        display: none;
      }

      .activeIcon {
        display: block;
      }
    }
  }

  &.position4 {
    bottom: 120px;
    right: 246px;

    &:hover {
      background-image: url('~@/assets/images/cooperation/guangzhou_hover.png');
      background-repeat: no-repeat;

      .defaultIcon {
        display: none;
      }

      .activeIcon {
        display: block;
      }
    }
  }

  &.position5 {
    bottom: 82px;
    right: 216px;

    &:hover {
      background-image: url('~@/assets/images/cooperation/shenzhen_hover.png');
      background-repeat: no-repeat;

      .defaultIcon {
        display: none;
      }

      .activeIcon {
        display: block;
      }
    }
  }

  &.position6 {
    top: 430px;
    left: 388px;

    &:hover {
      background-image: url('~@/assets/images/cooperation/chengdu_hover.png');
      background-repeat: no-repeat;

      .defaultIcon {
        display: none;
      }

      .activeIcon {
        display: block;
      }
    }
  }

  &.position7 {
    top: 314px;
    left: 448px;

    &:hover {
      background-image: url('~@/assets/images/cooperation/xian_hover.png');
      background-repeat: no-repeat;

      .defaultIcon {
        display: none;
      }

      .activeIcon {
        display: block;
      }
    }
  }

  &.position8 {
    bottom: 132px;
    right: 138px;

    &:hover {
      background-image: url('~@/assets/images/cooperation/fuzhou_hover.png');
      background-repeat: no-repeat;

      .defaultIcon {
        display: none;
      }

      .activeIcon {
        display: block;
      }
    }
  }
}

.mitoWrap {
  position: relative;
  width: 77px;
  height: 77px;
}

.defaultIcon {
  display: block;
  margin: 0px auto;
  width: 21px;
}

.activeIcon {
  display: none;
  margin: 0px auto;
  width: 28px;
}

.mito {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 34px;
  z-index: 10;
  cursor: pointer;
}

.name {
  margin-top: 4px;
  font-size: 14px;
  color: #A9A9A9;
}