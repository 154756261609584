@import '../../assets/scss/_base.scss';
.backTop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 20px;
  bottom: 50px;
  z-index: 10;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;

  &:hover {
    opacity: 1;
    animation: wiggle 1s ease;
    animation-iteration-count: 1;

  }

  @keyframes wiggle {
    20% {
      transform: translateY(6px);
    }

    40% {
      transform: translateY(-6px);
    }

    60% {
      transform: translateY(4px);
    }

    80% {
      transform: translateY(-2px);
    }

    100% {
      transform: translateY(0);
    }
  }
}

.backtopPic {
  display: block;
  width: 30px;
  height: 30px;
}

.bg {
  color: $baseColor;
}