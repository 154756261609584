@import '../../../assets/scss/_base.scss';

.titleText {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  text-align: center;
}

.formInput {
  border-radius: 0px;
}

.tips {
  font-size: 12px;
  color: #C1C2C5;
}

.btns {
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center;
}

.submitBtn {
  display: inline-block;
  padding: 0px;
  width: 110px;
  height: 32px;
  line-height: 32px;
  background-color: #5F4AF6;
  font-size: 12px;
  color: #FFFFFF;
  border-radius: 100px;

  &:hover {
    color: #FFFFFF;
  }
}