@import '../../../assets/scss/_base.scss';
.main {
  position: relative;
}

.firstScreen {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.backgroundImage {
  display: block;
  width: 100%;
  transform: scale(1.02);
  transition: all 1s;

  &:hover {
    transform: scale(1);
  }
}

.firstContent {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  width: 1000px;
  line-height: 1.3;
}

.firstTitle {
  font-size: 65px;
  font-weight: 550;
  color: #FFFFFF;
  text-align: center;
}

.firstDesc {
  margin-top: 24px;
  font-size: 24px;
  color: #FFFFFF;
  text-align: center;
}

.entrance {
  position: absolute;
  left: 50%;
  bottom: -345px;
  transform: translateX(-50%);
  padding-top: 26px;
  width: 1599px;
  height: 490px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 7px 15px 1px rgba(171, 212, 249, 0.24);
  z-index: 100;
}

.entranceContent {
  display: flex;
  align-items: center;
  margin: 52px auto 0px auto;
}

.entranceCol {
  flex: 1;
  text-align: center;

  h4 {
    margin-bottom: 12px;
    font-size: 24px;
    color: #333333;
  }

  p {
    font-size: 16px;
    color: #666666;
    line-height: 26px;
  }
}

.imgBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto 24px auto;
  width: 125px;
  height: 125px;

  .pic {
    display: block;
  }
}
