@import '../../../assets/scss/_base.scss';
.increment {
  position: relative;
  height: 870px;
  background-color: #FFFFFF;
}

.content {
  position: absolute;
  top: -80px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  width: 1600px;
}

.slideContent {
  position: relative;
}

.textWrap {
  position: absolute;
  top: 100px;
  left: 120px;
  padding: 50px 24px 80px 24px;
  width: 332px;
  height: 378px;
  background-color: #1A1A1C;
  color: #FFFFFF;

  h3 {
    margin-bottom: 16px;
    font-size: 36px;
    color: #0F78F8;
  }

  h4 {
    margin-bottom: 40px;
    font-size: 36px;
    color: #FFFFFF;
  }

  p {
    font-size: 18px;
    line-height: 28px;
  }
}

.pic {
  display: block;
  width: 1600px;
}

.slidePagination {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 478px;
  left: 120px;
  padding: 0px 24px;
  width: 332px;
  height: 184px;
  background-color: #242328;
}

.swiperButton {
  display: block;
  width: 10px;
  height: 19px;

  &.buttonPrev {
    background-image: url('~@/assets/images/incrementServer/icon_left.png');
    background-repeat: no-repeat;
  }

  &.buttonNext {
    background-image: url('~@/assets/images/incrementServer/icon_right.png');
    background-repeat: no-repeat;
  }
}

.paginationContent {
  margin: 0px 24px;
  width: 150px;
}

.page {
  text-align: center;
  font-size: 18px;
  color: #7F7E85;
}

.activePage {
  color: #FFFFFF;
}

.progressbar {
  margin-top: 12px;
  width: 150px;
  height: 1px;
  background-color: #D8D8D8;

  .progressbarInner {
    background-color: #1890ff;
    height: 1px;
  }
}

.paginationDesc {
  margin-top: 12px;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
}