@import '../../../assets/scss/_base.scss';
.productLand {
  padding: 60px 0px;
  background-color: #FFFFFF;
}

.container {
  margin: 0px 160px;
}

.title {
  margin-bottom: 60px;
  color: #333333;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
}

.listitem {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 45px;
  padding-right: 45px;
  height: 167px;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.102);
  border-radius: 0px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}

.listitemPrefix {
  margin-right: 35px;
  flex: none;
  width: auto;
}

.icon {
  display: block;
}

.listitemMain {
  flex: 1;
}

.name {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #331B6E;
}

.description {
  font-size: 16px;
  font-weight: 400;
  color: #68747F;
  line-height: 20px;
}
