@import '../../../assets/scss/_base.scss';
.contactLand {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 344px;
  background-image: url('~@/assets/images/toolsbox/contact_background.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  width: 1200px;
}

.title {
  font-size: 48px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  line-height: normal;
}

.btn {
  margin: 48px auto 0px auto;
  width: 211px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  background-color: #5F4AF6;
  border-radius: 30px 30px 30px 30px;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}