@import '../../assets/scss/_base.scss';
.siteHeader {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 999;
  background-color: transparent;
  transition: all 0.3s ease-out;

  &.default {
    object-fit: cover;
    background-repeat: repeat;
    background-color: rgba(51, 51, 51, 0.1);
    backdrop-filter: none;
    z-index: 999999;
  }

  &.fixedHeader {
    background-color: rgba(51, 51, 51, 1);
    box-shadow: 0px 2px 10px 0px rgba(51, 51, 51, 1);
  }
}

.container {
  margin: 0px auto;
  display: flex;
  align-items: center;
  width: 1600px;
  height: 70px;
}

.navLogo {
  width: 144px;

  img {
    width: 144px;
    height: 37px;
  }
}

.navMenu {
  flex: 1;
  display: flex;
  justify-content: right;

  li {
    display: inline-block;
    margin-left: 30px;

    &:first-child {
      margin-left: 0px;
    }
  }

  .menuItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 70px;
    font-size: 16px;
    color: #ffffff;

    &.active {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        height: 2px;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 2px;
        transform: all 0.2s;
      }
    }
  }
}

