@import '../../../assets/scss/_base.scss';
.contactWrap {
  padding-top: 78px;
  display: block;
  width: 100%;
  height: 288px;
  background-image: url('~@/assets/images/supply/contact_background.png');
  background-repeat: no-repeat;
}

.title {
  margin: 0px auto;
  width: 1600px;
  font-size: 36px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.btn {
  display: block;
  margin: 30px auto 0px auto;
  width: 140px;
  height: 56px;
  line-height: 56px;
  background-color: #1C8CF2;
  border-radius: 6px 6px 6px 6px;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;

  &:hover {
    color: #FFFFFF;
    background-color: #1C8CF2;
  }
}