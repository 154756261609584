@import '../../../assets/scss/_base.scss';
.featureWrap {
  padding-top: 60px;
  padding-bottom: 100px;
  background-color: #FFFFFF;
}

.title {
  margin: 0px auto;
  width: 1530px;
  font-size: 48px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.content {
  display: flex;
  flex-wrap: wrap;
  margin: 40px auto 0px auto;
  width: 1530px;
}

.listItem {
  margin-bottom: 32px;
  padding-left: 23px;
  width: 518px;
  height: 320px;
}

.item1 {
  margin-left: -24px;
  background-image: url('~@/assets/images/supply/list_item_46842.png');
  background-repeat: no-repeat;
}

.item2 {
  background-image: url('~@/assets/images/supply/list_item_46841.png');
  background-repeat: no-repeat;
}

.item3 {
  background-image: url('~@/assets/images/supply/list_item_46840.png');
  background-repeat: no-repeat;
}

.item4 {
  margin-left: -24px;
  background-image: url('~@/assets/images/supply/list_item_46837.png');
  background-repeat: no-repeat;
}

.item5 {
  background-image: url('~@/assets/images/supply/list_item_46838.png');
  background-repeat: no-repeat;
}

.item6 {
  background-image: url('~@/assets/images/supply/list_item_46839.png');
  background-repeat: no-repeat;
}

.name {
  margin: 0px 45px;
  height: 120px;
  line-height: 120px;
  font-size: 28px;
  font-weight: bold;
  color: #FFFFFF;
}

.desc {
  margin: 36px 45px 0px 45px;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 1.5;
}
