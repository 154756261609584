@import '../../../assets/scss/_base.scss';
.tradeLand {
  background-color: #FFFFFF;
}

.container {
  margin: 0px 160px;
  padding-top: 30px;
  padding-bottom: 40px;
}

.header {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 30px;
  font-weight: 400;
  color: #333333;
}

.label {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
}

.label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.body {
  padding-left: 64px;
  padding-right: 64px;
}

.thead {
  margin-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: rgba(255,255,255,0.1882);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1608);
  border-radius: 100px 100px 100px 100px;
}

.tbody {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 20px;
}

.th, .td {
  flex: none;
  padding-top: 13px;
  padding-bottom: 13px;
  font-size: 14px;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.td {
  display: flex;
  align-items: center;
  padding: 0px 16px 0px 0px;
  height: 40px;
}

.carouselWrap {
  height: 380px;
  overflow: hidden;

  :global {
    .ant-carousel {
      line-height: normal;
    }

    .ant-carousel .slick-list {
      overflow: initial;
    }

    .ant-carousel .slick-vertical .slick-slide {
      border: none;
    }
  }
}