@import '../../../assets/scss/_base.scss';
.enquireLand {
  width: 100%;
  height: 1312px;
  background-image: url('~@/assets/images/toolsbox/enquire_background.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  margin: 0px 160px;
  padding-top: 30px;
}

.header {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 30px;
  font-weight: 400;
  color: #FFFFFF;
}

.body {
  padding-left: 64px;
  padding-right: 64px;
}

.thead {
  padding-left: 30px;
  padding-right: 30px;
  background-color: rgba(255,255,255,0.1882);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1608);
  border-radius: 100px 100px 100px 100px;
}

.tbody {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}

.th, .td {
  flex: none;
  padding-top: 13px;
  padding-bottom: 13px;
  font-size: 14px;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.link {
  color: #1868FB;

  &:hover {
    color: #1868FB;
  }
}
