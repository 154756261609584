@import '../../assets/scss/_base.scss';
.footer {
  padding-top: 56px;
  padding-bottom: 72px;
  width: 100%;
  background-color: #181A1A;
}

.footerLinks {
  margin: 0px 200px;
  display: flex;
  align-items: center;
}

.flexPic {
  display: block;
  width: 223px;
  height: 67px;
}

.linksGroup {
  margin-left: 180px;
  flex: auto;
  display: flex;
  align-items: flex-start;
}

.menuItem {
  margin-left: 120px;

  &:first-child {
    margin-left: 0px;
  }
}

.menuTitle {
  margin-bottom: 24px;
  font-size: 18px;
  color: #FFFFFF;
}

.menuList {
  font-size: 12px;
  color: #999999;

  li {
    margin-bottom: 10px;
    padding-left: 14px;

    &:last-child {
      margin-bottom: 0px;
    }

    a {
      color: #999999;

      &:hover {
        color: #999999;
      }
    }
  }
}

.rightBox {
  font-size: 12px;
  color: #999999;

  p {
    margin-top: 10px;
    text-align: center;
  }
}

.wxPic {
  display: block;
  margin: 0px auto;
  width: 100px;
  height: 100px;
}

.divider {
  margin-top: 56px;
  margin-bottom: 20px;
  background-color: #353838;
}

.copyRights {
  margin: 0px 200px;
  text-align: center;
  font-size: 12px;
  color: #999999;
}

.link {
  color: #999999;

  &:hover {
    color: #999999;
  }
}
