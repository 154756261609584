@import '../../../assets/scss/_base.scss';
.contractformLand {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 528px;
  background-image: url('~@/assets/images/toolsbox/contract_form_background.png');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.container {
  width: 1200px;
}

.title {
  margin-bottom: 20px;
  font-size: 48px;
  font-weight: bold;
  line-height: normal;
  color: #FFFFFF;
  text-align: center;
}

.subtitle {
  font-size: 30px;
  font-weight: 400;
  color: #D6D6D6;
  text-align: center;
}

.formWrap {
  margin: 40px auto 0px auto;
  width: 920px;

  :global {
    .ant-form-item-label {
      line-height: 24px;
    }

    .ant-input-group-addon {
      padding: 0px;
      border: none;
    }
  }

  .addonBtn {
    display: flex;
    align-items: center;
    padding: 0px 12px;
    color: #FFFFFF;
    background-color: #FB9918;
    border-color: #FB9918;
    height: 32px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &.disabled {
      background-color: #FAFAFA;
      border-color: #D9D9D9;
      color: #999999;
    }
  }
}

.btns {
  margin-top: 32px;
  text-align: center;
}

.btn {
  display: block;
  margin: 0px auto;
  width: 211px;
  height: 54px;
  line-height: 54px;
  background: linear-gradient(180deg, #331B6D 0%, #5F33CD 100%);
  border-radius: 30px 30px 30px 30px;
  color: #FFFFFF;
  font-size: 14px;
  cursor: pointer;
}
