@import '../../../assets/scss/_base.scss';
.operateWrap {
  display: block;
  padding-top: 60px;
  width: 100%;
  height: 1080px;
  background-image: url('~@/assets/images/supply/operate_background.png');
  background-repeat: no-repeat;
}

.title {
  margin: 0px auto;
  width: 1600px;
  font-size: 48px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  line-height: normal;
}

.content {
  display: flex;
  margin: 220px auto 0px auto;
  width: 1600px;
}

.stepItem {
  flex: 1;
}

.stepContent {
  position: relative;
  width: 324px;
  height: 312px;
}

.arrow {
  position: absolute;
  right: -116px;
  top: 50%;
  transform: translateY(-50%);
  width: 154px;
  height: 33px;
  background-image: url('~@/assets/images/supply/arrow_right.png');
  background-repeat: no-repeat;
}

.step46844 {
  background-image: url('~@/assets/images/supply/operate_item_46844.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.step46845 {
  background-image: url('~@/assets/images/supply/operate_item_46845.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.step46846 {
  background-image: url('~@/assets/images/supply/operate_item_46846.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.step46847 {
  background-image: url('~@/assets/images/supply/operate_item_46847.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.stepInner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  width:324px;
  height: 312px;
  line-height: 1.5;
  font-size: 30px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
}

.stepDesc {
  width: 324px;
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  line-height: 1.5;
}
